/***************************
 * = COULEURS
 ***************************/

$color-primary: #df1826;
$color-primary-dark: #ba1521;
$color-primary-light: #f63a47;
$color-secondary: #36b0ec;
$color-secondary-dark: #2b91c3;
$color-secondary-light: #4fbff6;
$black: #2b383f;
$grey-dark: #727272;
$grey-light: #eff0f4;
$grey-medium: #d9d9d9;
$color-error: #a94442;
$color-valid: #0abf74;
$color-info: #3493dc;

/***************************
 * = FONTS
 ***************************/

// a supprimer des que plus utilisé dans themes.scss
%font-normal {
	font-family: "718-book", sans-serif;
}

// a supprimer des que plus utilisé dans themes.scss
%font-bold {
	font-family: "718-bold", sans-serif;
}

%text {
	@extend %font-normal;
	font-size: 1.4rem;
	line-height: 1.5;
	color: $black;
}

%text-bold {
	@extend %font-bold;
	font-size: 1.4rem;
	line-height: 1.5;
	color: $black;
}

%text-secondary {
	@extend %font-normal;
	font-size: 1.4rem;
	line-height: 1.5;
	color: $grey-dark;
}

%text-secondary-bold {
	@extend %font-bold;
	font-size: 1.4rem;
	line-height: 1.5;
	color: $grey-dark;
}

%link {
	@extend %font-bold;
	font-size: 1.4rem;
	line-height: 1.5;
	color: $color-primary;
	cursor: pointer;
	text-decoration: none;

	@include gridle_state(md) {
		&:hover {
			text-decoration: underline;
		}
	}
}

%master-button {
	text-transform: none;
	border-radius: 5px;
}

/***************************
 * = COMMUN
 ***************************/

$border-radius-small: 5px;
$border-radius-big: 0;
$border-radius-top: 0 0 0 0;
$border-radius-bottom: 0 0 $border-radius-big $border-radius-big;
$header-background-color: white;
$header-border-bottom: var(--color-gray-400, $grey-medium);
$header-menu-active-color: $black;
$header-text-color: $black;

$auth-header-background-color: white;
$auth-header-mobile-background-color: $auth-header-background-color;
$auth-header-text-color: $black;
$auth-header-mobile-text-color: $auth-header-text-color;

$cookie-policy-footer-background: $black;
$cookie-policy-footer-color: white;
$cookie-policy-footer-text-transform: uppercase;
$phone-number-color: $color-primary;
$common-box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);

$badge-background-color: linear-gradient(to right, #3995f0 0%, #36b2ec 100%);

$payment-banner-background-color: $color-secondary;

/***************************
 * = FORM
 ***************************/

$active-color: $color-secondary;
$active-color-dark: $color-secondary-dark;
$active-color-light: $color-secondary-light;
$input-border-color: $grey-medium;
$input-border: 1px solid var(--color-active, $active-color);
$input-border-radius: $border-radius-small;
$radio-checked-background: $grey-light;
$radio-hover-border-color: $active-color-light;
$radio-outline: none;
$radio-outline-error: none;
$input-shadow-box: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
$button-primary-background-color: $color-primary;
$button-primary-background-color-dark: $color-primary-dark;
$button-box-shadow: none;

/***************************
 * = FICHE PRODUIT
 ***************************/
$product-accommodation-text-transform: uppercase;

$product-deal-text-color: white;

$offer-mobile-background-color: $black;

$bloc-contact-title-case: uppercase;
$bloc-contact-body-background: $black;
$bloc-contact-text-color: white;

$product-aside-box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);

$datepicker-background-color: $black;

/***************************
 * = MOTEUR DE RECHERCHE
 ***************************/

$search-engine-offer-color: $black;
$search-engine-dark-scheme: --color-secondary-light;

/***************************
 * = TUNNEL DE REZA | COMMUN
 ***************************/
$booking-bloc-title-main-background-color: $black;
$booking-item-margin: 15px;
$booking-cta-background-color: #0abf74;
$quotation-price-detail-toggle-button-background: $black;
$quotation-price-detail-total-background: $black;
$quotation-price-detail-total-border: 1px solid $black;
$quotation-price-detail-total-font-color: white;
$quotation-price-detail-pay-now-border: 1px solid white;
$quotation-price-detail-text-color: white;
$quotation-price-detail-total-margin-bottom: 10px;

/***************************
 * = TUNNEL DE REZA | QUOTE
 ***************************/
$booking-badge-background-color: linear-gradient(to right, #3995f0 0%, #36b2ec 100%);
$badge-font-color: white;
$badge-border: none;
$badge-border-radius: 40px;

$quotation-filter-sort-background: white;

$flight-filter-button-background-color: $grey-light;
$flight-filter-button-color: $black;
$flight-filter-button-border-radius: $border-radius-big;
$flight-filter-background-color: $active-color;

$quotation-luggage-included-border-radius: $border-radius-small;
$quotation-luggage-included-outline: unset;

/***************************
 * = TUNNEL DE REZA | PAYMENT
 ***************************/
$insurances-radio-second-color: #d9d9d9;

/***************************
 * = TUNNEL DE REZA | CONFIRMATION
 ***************************/
$booking-confirmation-reference-color: $color-primary;

/***************************
 * = SMARTDP
 ***************************/

/***************************
 * = LISTING
 ***************************/
$product-cta-background-color: $button-primary-background-color;
$product-cta-border: 1px solid $button-primary-background-color;
$product-cta-button-radius: inherit;
$product-deal-public-price-line-through-color: $black;
$product-intemporelle-color: $color-primary;
$listing-actionbar-title-display-on-mobile: block;
$listings-product-background-color: $grey-light;
$colored-product-badge-background-color: $color-secondary;
$colored-product-badge-text-transform: uppercase;
$colored-product-badge-border-radius: 5px;
$merchandising-simple-width: 100%;
$listing-time-background-color: linear-gradient(to right, #3995f0 0%, #36b2ec 100%);

/***************************
 * = AUTH
 ***************************/
// auth form background that appears on a background that do not change (auth, booking/auth, home)
$auth-form-background-static: rgba(0, 0, 0, 0.22) !default;
$auth-form-text-color-static: white !default;
// auth form background that appears on a background that can change over time (sign-up, landing product)
$auth-form-background-dynamic: rgba(255, 255, 255, 0.8) !default;
$auth-form-text-color-dynamic: $black !default;
$auth-marketing-message-shadow: linear-gradient(
	to bottom,
	rgba(4, 45, 87, 0.86),
	rgba(39, 83, 128, 0)
) !default;

/***************************
 * = PLACEHOLDERS
 ***************************/

%booking-title {
	display: flex;
	align-items: center;
	justify-content: center;
	color: white;
	box-sizing: border-box;
	text-align: center;
	min-height: 40px;
	padding: 10px 0;
}

%input-label-touched {
	position: absolute;
	top: 2px;
	left: 5px;
	font-size: 1rem;
	color: var(--color-active, $active-color);
	background: none;
	padding-right: 10px;
	padding-left: 10px;
}

$auth-background-color: $color-primary;
